import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled('div')`
  width: clamp(400px, 50vw, 960px);
  margin: 0 auto;
  margin-bottom: 240px;
  position:relative;
  top: 160px;
  
  & > *{
    text-align: left;
  }
  h1 {
   text-transform: uppercase;
  }
  h2 {
    font-weight: 700;
    margin-top: 24px;
    font-size: 26px;
    margin-bottom: 0;
  }
  h3 {
    margin-top: 24px;
    font-size: 20px;
  }
  & time {
    margin-bottom: 24px;
    font-size: 12px;
    display: block;
  }
  & span.link {
    display: block;
    margin-top: 48px;
  }
  hr {
  height: 1px;
  border: none;
  background-color: var(--sp-orange);
  margin-top: 24px;
  }
  li {
  line-height: 1.6;
  }
`

const ReleaseNotesPage = () => (
  <Layout navForced>
    <SEO title={'Sheetplanner - Release Notes'}/>
    <Content>
      <h1>Release Notes</h1>
      
      
      <h2>SheetPlanner 4.0</h2>
      <time>September 2nd, 2023</time>
      
      <p>
        SheetPlanner 4.0 is a major update to this spreadsheet-like project planning tool. Major enhancements in version 4 include:
        <ul>
          <li>SheetPlanner now requires macOS Monterey (12.0) or later.</li>
          <li>Added a new kanban-style Board view to arrange lanes of cards.</li>
          <li>Completion and Progress are now linked.</li>
          <li>List cells now display customizable colors for each list item.</li>
          <li>Move cells around the Outline view via keyboard shortcuts.</li>
          <li>Plus many other improvements; see the full release notes on the SheetPlanner website for details.</li>
        </ul>
      </p>

      <p>
        Read on for a detailed list of changes.
      </p>

      <h3>Improved free trial</h3>
      <ul>
        <li>No longer displays the unlock options on first launch.</li>
        <li>Now automatically starts the trial on first launch, so all features can be tried for free.</li>
        <li>The trial now only counts days the app is open, so taking a break from the trial won't use up trial days.</li>
      </ul>

      <h3>Board view is a paid upgrade</h3>
      <ul>
        <li>Version 4 includes a powerful new <strong>Board</strong> view. To use it, a paid upgrade is required, other than for recent purchasers.</li>
        <li>All other current features remain available for previous purchasers.</li>
        <li>The upgrade trial only counts days on which the Board view are displayed.</li>
        <li>The Upgrade bar appears only while the Board view is displayed, to emphasize that the upgrade is related to that view.</li>
        <li>The Upgrade window appears at the end of the trial, with options to just keep using the already unlocked features, or buy an upgrade.</li>
        <li>Attempting to show the Board view after the trial has ended will instead show the Upgrade window.</li>
        <li>The Unlock/Upgrade window now uses dynamic text, so it's easier to change the wording.</li>
      </ul>

      <h3>Board view</h3>
      <ul>
        <li>Added a new kanban-style Board view to arrange lanes of cards.</li>
        <li>Supported column content and types include Completed, Topic, List, and Symbol.</li>
        <li>Supports vertical and horizontal lanes, which can be collapsed or expanded.</li>
        <li>Clicking a selected card shows an Info popover; the popover can be detached by dragging its arrow.</li>
        <li>Can Command-click cards to select multiple cards, or drag a selection from empty space.</li>
        <li>Can drag cards between vertical and/or horizontal lanes to move them.</li>
        <li>Cards show a menu button when hovering over them.</li>
        <li>This menu includes the options from the View / Board menu to change the lanes used for the Board view.</li>
        <li>It also includes a command to Collapse/Expand Cards, which removes or adds the values after the topic.</li>
        <li>When expanded, the menu also includes a command to Show/Hide Value Names, which shows or hides name labels above each value.</li>
        <li>The menu also includes the available card values, like in the Board Inspector, to quickly show or hide values.</li>
        <li>All these options are also available in the View / Board menu, and the Board Inspector.</li>
        <li>When the Outline view is hidden, pressing arrow keys will select cards based on their positions on the board.</li>
        <li>Arrow selection also supports Cmd-arrows to select multiple cards. (Shift-arrows is equivalent.)</li>
        <li>Pressing the Space bar when a single card is selected will show the info popover. (Press Escape to dismiss.)</li>
      </ul>

      <h3>Board Inspector</h3>
      <ul>
        <li>Added a Board section to the Inspector, included when the Board view is displayed.</li>
        <li>Includes menus to choose the vertical and horizontal lanes, like in the View / Board menu.</li>
        <li>Includes a list of checkboxes to choose which values to include in the cards; they can be dragged to change the order.</li>
      </ul>

      <h3>Completion and Progress are now linked</h3>
      <ul>
        <li>When a row is marked as complete, the Progress column is set to 100%.</li>
        <li>When a row is marked as incomplete, the Progress column is set back to what it was before 100%.</li>
        <li>When the Progress is set to 100%, the row is marked as complete.</li>
        <li>When the Progress is set to less than 100%, the row is marked as incomplete.</li>
        <li>The Completion pie now reflects the Progress value in the Outline and Board views.</li>
        <li>Existing documents are updated to reflect this change.</li>
      </ul>

      <h3>List colors</h3>
      <ul>
        <li>The List Inspector now includes color pickers for each list item.</li>
        <li>List cells now display the color of their value.</li>
        <li>The list picker menu now also shows the colors.</li>
        <li>The People list picker also shows the icons and colors.</li>
      </ul>

      <h3>Move submenu</h3>
      <ul>
        <li>Added a Move submenu to the Organize menu, with items to move selected rows up, down, left, or right.</li>
        <li>The rows are only moved within their parents.</li>
        <li>Multiple rows can be moved at once (including from different parents).</li>
        <li>Undo is supported.</li>
      </ul>

      <h3>Menus and toolbar changes</h3>
      <ul>
        <li>Rearranged the View menu, to put the views and their options into common submenus.</li>
        <li>When the focused row is selected, the Focus toolbar button now changes to <em>Unfocus</em>, as an alternative to the circled x icon in the Topic header.</li>
        <li>Fixed the Hide Column item in the column menus not being enabled.</li>
        <li>Fixed the Delete Column item in the column menu not being enabled for custom columns.</li>
        <li>Added a SheetPlanner Release Notes item to the Help menu, to show the full release notes on the website.</li>
      </ul>

      <h3>Templates and opening documents</h3>
      <ul>
        <li>Added templates for Sprint Planning Board and Software Development.</li>
        <li>Updated the To Do List template.</li>
        <li>When saving a new custom template, it now automatically shows the rename popover, to make it easier to give it a better name.</li>
        <li>Added an Open Recent drop-down menu to the Template Picker, as an alternative to the File / Open Recent menu.</li>
        <li>Fixed a crash opening a document on some machines.</li>
        <li>Improved file caching to avoid conflicts when editing two documents with the same name.</li>
      </ul>

      <h3>Sharing</h3>
      <ul>
        <li>Changed the Sharing toolbar icon to the macOS standard icon.</li>
        <li>The Share toolbar button now uses the sharing popover introduced in macOS Ventura.</li>
        <li>When choosing File / Share..., the sharing panel now appears as a popover from the Share toolbar button.</li>
        <li>Moved the Show/Hide Highlights menu command to the View menu, and renamed Show/Hide Change Highlights to make the function more clear.</li>
      </ul>

      <h3>Sidebar</h3>
      <ul>
        <li>Fixed the sidebar limiting resizing in some situations.</li>
        <li>Double-clicking the sidebar divider will change it to a default width (proportional to the window size).</li>
        <li>Right-clicking on the sheet name when not already editing it will now display the sidebar contextual menu.</li>
        <li>Fixed duplicate sheets in the sidebar.</li>
        <li>Fixed missing sheet if not edited.</li>
        <li>Fixed contextual menu in sidebar.</li>
        <li>Fixed renaming a sheet.</li>
        <li>Fixed incorrect document handling behavior when importing to a new document.</li>
      </ul>

      <h3>Outline view changes</h3>
      <ul>
        <li>The Outline's Progress cell now treats a blank value as zero, so it always shows the bar, pie, or percentage.</li>
        <li>Fixed the contextual menu when right-clicking on a cell.</li>
        <li>Fixed themes in the Outline view.</li>
        <li>Replaced the Progress cell bar with a custom view to avoid excessive blinking of the standard view.</li>
        <li>The Outline now automatically discloses rows when needed for jumping to predecessors or successors, or for Board navigation.</li>
        <li>Updated all of the color pickers to use the macOS native control instead of a buggy third-party one, now that macOS supports a nicer control with a color palette popover.</li>
        <li>List columns now use Summary when they become a parent (unless they have a custom value assigned; calculations don't override custom values).</li>
        <li>When indenting rows, the connections are now adjusted to bypass the new parent row, since parent rows can't have connections.</li>
        <li>When a row is indented or outdented, or moved left or right, the row height is now adjusted.</li>
        <li>Fixed cells not editing after their column was unhidden or initially added.</li>
        <li>Fixed the Progress and Completion Date cells ending ending while typing; they now wait a few second before updating (being linked).</li>
        <li>Fixed autocomplete when typing in a People cell.</li>
        <li>Fixed assigning calculations to cell values when they become a parent via indenting or dragging.</li>
        <li>Fixed disconnecting a dependency for parent rows.</li>
        <li>Pressing the Escape key will now unfocus if focused, cancel filtering if filtering, or exit the print preview if that's shown.</li>
        <li>Fixed undoing unfocusing.</li>
      </ul>

      <h3>Timeline view changes</h3>
      <ul>
        <li>Removed the extraneous disclosure triangles in the Timeline view.</li>
        <li>When printing the Timeline, it now defaults to no empty columns before the first populated one (this can be changed in the Print Inspector).</li>
        <li>Fixed a rare issue with Timeline child rows not appearing.</li>
        <li>Fixed missing horizontal and vertical grid lines in the Timeline view.</li>
      </ul>

      <h3>Calendar views</h3>
      <ul>
        <li>The Month and Year views now show only the matching items when focusing or filtering.</li>
        <li>Improved the scrolling behavior when unfocusing or canceling filtering.</li>
        <li>The Topic Column Inspector's Completed style (Hidden, Checkbox, or Pie) is now used in the Month view.</li>
      </ul>

      <h3>Inspector changes</h3>
      <ul>
        <li>Rows can now be selected in the List and People Inspectors, to make it more intuitive that they can be reordered and deleted.</li>
        <li>Fixed adding rows in the List and People Inspectors, so they are selected and editing when added.</li>
        <li>Added a tooltip to the Date Column Inspector color to explain that this color used in the Month view.</li>
      </ul>

      <h3>Other changes</h3>
      <ul>
        <li>Fixed filters using Any Column when evaluating the People column.</li>
        <li>Changed the logic of searching/filtering to be consistent between using Any Column or a specific column.</li>
        <li>Vertically centered the date picker button in the date cells.</li>
        <li>Fixed an error for some people with the newsletter signup form.</li>
        <li>Added a control in the Settings to enable overriding the system appearance to always use light or dark mode.</li>
        <li>Updated the onboarding screens and What's New for version 4.</li>
        <li>SheetPlanner now requires macOS Monterey (12.0) or later.</li>
      </ul>
      
      {/*-----------------------------*/}
      
      <h2>SheetPlanner 3.0</h2>
      <time>May 25th, 2022</time>

      <h3>Important notes</h3>
      <ul>
        <li><span role="img" aria-label={"warning"}>⚠️</span> The document format changed in version 3.0, so version 2.0.6 and earlier won't be able to open documents opened in version 3.0.</li>
        <li><span role="img" aria-label={"warning"}>🖥</span> SheetPlanner now requires macOS Catalina (10.15) or later.</li>
      </ul>

      <h3>Multiple sheets and tabs</h3>
      <ul>
        <li>Everyone can now use multiple sheets and tabs in a document.</li>
        <li>A sidebar is available to organize multiple sheets.</li>
        <li>Sheets, Notes, and Links can be displayed in a new tab.</li>
        <li>These were previously only available to paid users; now also available in free mode.</li>
      </ul>

      <h3>Sync</h3>
      <ul>
        <li>Saving to iCloud Drive is now supported. New documents are saved in iCloud Drive by default, but can be saved elsewhere if desired.</li>
        <li>When saved to iCloud Drive, sharing options are available. See Share ▸ Share File menu, and the Share toolbar button.</li>
        <li>Detects when a document is changed on two or more computers at once, and merges the changes, to avoid one overwriting the other's.</li>
        <li>Now uses Quick Look preview and thumbnail app extensions to dynamically provide those to the Finder, to avoid conflicts when editing a document on multiple Macs.</li>
        <li>Quick Look previews can be zoomed and scrolled, though are static snapshots of the window content.</li>
        <li>Added support for Mac-specific bookmarks for file links, so finding a file on one Mac won't invalidate the link on another Mac.</li>
        <li>The showing state of notes and links is now also Mac-specific.</li>
        <li>Changed the way the outline hierarchy is restored when loading a document, to minimize changes needed to be saved, thus reducing conflicts when syncing.</li>
      </ul>
      
      <h3>Collaboration selection</h3>
      <ul>
        <li>When another Mac makes changes to a document, the changed cells are briefly highlighted.</li>
        <li>Added a command to Show/Hide Highlights. When shown, all cells that have been edited are highlighted with the person's color. When hidden, cells are only highlighted when changed remotely.</li>
        <li>When two or more people are editing the same document at the same time, the other people's selections are displayed as thin lines, using their highlight colors.</li>
      </ul>

      <h3>People column</h3>
      <ul>
        <li>Added a standard People column, hidden by default, that is a List-type column pre-populated with the names of people collaborating on a document.</li>
        <li>When the People column is displayed, it works the same as other List columns: click the menu button to show a menu of people, or type a name. The cell also displays the selected person's color; this color is used to highlight their changes.</li>
        <li>Can add and remove custom people via the Inspector.</li>
        <li>Collaborators and custom people use different icons in the People column.</li>
        <li>In the Column Inspector, the color assigned to each person can be changed.</li>
        <li>Also in the Inspector, the names can be edited, e.g. to use a shorter name.</li>
        <li>The Inspector also includes an option to choose multiple people, or only one.</li>
        <li>When one or more people have been assigned to a row via the People column, and a notification is set for a date column, now only those people are notified.</li>
        <li>Updated the Project Plan and Sales Pipeline templates to use the People column instead of a custom List column.</li>
      </ul>

      <h3>Cell Inspector</h3>
      <ul>
        <li>When a cell that has been changed is selected, the Cell Inspector now displays information about it, including:
          <ul>
            <li>The date and time the cell was last changed.</li>
            <li>The highlight color and name of the person who made the change.</li>
            <li>The name of the computer where the change was made.</li>
          </ul>
        </li>
      </ul>
      
      <h3>Smart filters</h3>
      <ul>
        <li>Added the ability to filter on Row attributes, like whether a row is a task (has a duration) or milestone (no duration), is a parent (has sub-rows) or a child (no rows within), is unchanged, was changed by you, or was changed by someone else, etc.</li>
        <li>Fixed the Note filtering to properly support searching within notes, from start of notes, etc.</li>
        <li>Smart filters now support filtering on the People column.</li>
      </ul>
      
      <h3>More compact documents</h3>
      <ul>
        <li>The SheetPlanner document is now a single file, instead of a package (a folder of files presented as one file).</li>
        <li>This makes syncing faster and more reliable, in addition to taking up less space on disk, among other benefits.</li>
        <li>Note that documents opened in version 3.0 and later won't be able to be opened in version 2.0.6 or earlier.</li>
        <li>Documents saved by SheetPlanner 3 use the file extension ".sp".</li>
      </ul>
      
      <h3>Debug</h3>
      <ul>
        <li>Added a command in the Debug menu to open the diagnostic log file, that records information to help debug the app (it is replaced each day).</li>
        <li>Also added a command to open the Console, which logs that and other Apple framework detritus.</li>
        <li>Added Debug menu options to add the computer name or app edition name to the person name. Useful to test the People column without multiple people.</li>
      </ul>
      
      <h3>Other changes</h3>
      <ul>
        <li>On macOS 11 and later, the window now has the title above the toolbar, instead of to the left, so toolbar buttons are spaced out instead of scrunched to the right.</li>
        <li>When a cell is selected, the color picker is now updated to reflect the text color of that cell.</li>
        <li>The Inspector no longer scrolls to the top when changing the cell selection etc.</li>
        <li>When a document is saved, its print settings are saved as the default.</li>
        <li>When a template is loaded, the default print settings are loaded.</li>
        <li>When opening a template, the sheet name now defaults to the template name.</li>
        <li>When adding a sheet to an existing document, the sheet name has a number appended to make it unique, if necessary.</li>
        <li>As a special case, adding a blank sheet uses the name "Sheet", with a number if needed.</li>
        <li>Fixed row numbers being zeroes when using Duplicate Sheet & Rows.</li>
        <li>Fixed a curly issue where a cell could unexpectedly stop editing.</li>
        <li>When copying a Topic cell with notes and/or links, those are now also included when pasting into a document.</li>
        <li>When opening a document on a different computer, the first sheet is selected by default, instead of none.</li>
        <li>The Month and Year view headers now better adapt between light and dark modes.</li>
        <li>Fixed Month and Year views not updating when in a different tab (not a sync-related issue).</li>
        <li>Updated the Welcome to SheetPlanner screens.</li>
      </ul>
      
      
      {/*----------------------------------------*/}
      
      <h2 id="b10">SheetPlanner 2.0</h2>
      <time>Dec 29, 2020</time>
      <h3>Big Sur compatibility</h3>
      <ul>
        <li>Updated the app icon and sidebar icons for the new Big Sur style.</li>
        <li>Updated for Big Sur (macOS 11) compatibility, but still supports back to High Sierra (macOS 10.13).</li>
        <li>SheetPlanner is now a universal app for Intel and Apple M1 silicon.</li>
      </ul>

      <h3>Free mode</h3>
      <ul>
        <li>The Outline view can now be used free of charge, including all outlining features, and saving documents.</li>
        <li>When in free outliner mode, the toolbar and menus are simplified to omit paid features, including the Sidebar, Timeline, Month, and Year views, smart filters, and multiple tabs.</li>
        <li>In free mode, if opening a document that contains multiple sheets, they appear as multiple unclosable tabs.</li>
        <li>The purchase bar at the top of the window now only appears during a trial of the paid features.</li>
      </ul>

      <h3>Welcome window</h3>
      <ul>
        <li>When first running SheetPlanner, a window will appear with a slideshow introducing the features of the app.  This can be re-displayed later via the Help ▸ Welcome to SheetPlanner menu command.</li>
        <li>When updating to a new build of the app, a window will appear with "What's New in SheetPlanner".  This can be re-displayed later via the Help ▸ What's New in SheetPlanner menu command.</li>
        <li>When the app hasn't been purchased, a window will be displayed with options to use the free outliner, start a trial, unlock the full features, restore a purchase, or learn more.</li>
        <li>Even if the trial has been completed in version 1, it will be available to try again in version 2.</li>
        <li>After the unlock page, a newsletter signup page will be displayed.  This can be re-displayed via Help ▸ Sign Up for the SheetPlanner Newsletter.</li>
      </ul>

      <h3>Help & Tutorials window</h3>
      <ul>
        <li>Added a new Help & Tutorials window.</li>
        <li>This is displayed via the Help ▸ SheetPlanner Help menu command.</li>
        <li>Includes a sidebar that lists the help topics and video tutorials.</li>
        <li>Clicking a help section displays the help for that topic.</li>
        <li>Clicking a video displays the corresponding video tutorial.</li>
      </ul>

      <h3>Multiple sheets in a document</h3>
      <ul>
        <li>Added a sidebar to the left of the Outline view in the document window, including built-in and custom folders and sheets.</li>
        <li>A document can now include multiple sheets, each with their own columns, rows, styles, filters, etc.</li>
        <li>Built-in folders include all sheets, recently accessed sheets, favorite sheets, and deleted sheets.</li>
        <li>Each tab or window of a document can have a different sheet selected, and each with their own shown views and selections.</li>
        <li>Added items to the Add toolbar menu to add new folders and sheets.</li>
        <li>Added a new Sheet menu to the menubar, with commands to add new folders and sheets, duplicate, rename, delete, and navigate between sheets.</li>
        <li>Can click on a selected folder or sheet to rename it.</li>
        <li>Can click the star icon on a selected sheet to mark it as a favorite.</li>
        <li>Folders and sheets can be dragged to rearrange them.</li>
        <li>The view state is stored for each sheet of each tab of each computer.</li>
        <li>The selected sheet name is displayed in the window/tab title.</li>
        <li>This is only available in the trial and purchased modes.</li>
      </ul>

      <h3>Document changes for multiple sheets and future sync features</h3>
      <ul>
        <li>Major changes to the document format to support the big new feature of multiple sheets in a document.</li>
        <li>Support for organizing sheets into nestable folders.</li>
        <li>Support for multiple people and/or Macs editing a document via cloud syncing (coming in a future version), each with their own tabs, sheet and cell selection, etc.</li>
        <li>Numerous changes to improve the performance of document access.</li>
        <li>Plus other changes for the latest version of Swift.</li>
        <li><span role={"img"} aria-label={"caution"}>🚧</span> NOTE: the document format has changed, so documents opened by this version onwards can't be opened in version 1.2 and earlier.</li>
      </ul>

      <h3>My Templates</h3>
      <ul>
        <li>Added a File ▸ Save as Template… command to save the current document as a custom template, that you can use as a basis when making new documents.</li>
        <li>Custom templates are listed in a new "My Templates" section of the Template Chooser (which is only included if some have been saved).</li>
        <li>Right-click on a custom template in the chooser for menu commands to rename or delete it.</li>
        <li>Improved the Template Chooser to work better from the keyboard: you can now just arrow around to select a template, then press the Return key to open it.</li>
        <li>Updated the appearance of the Template Chooser window.</li>
      </ul>

      <h3>Caboodle import</h3>
      <ul>
        <li>Added support for importing documents from Caboodle, maintaining the hierarchy and content of the entries.</li>
        <li>Custom fields are added as columns, and entry text are added as notes.</li>
        <li>Supports both version 1 (from Dejal) and version 2 (from Before Dawn) of Caboodle.</li>
        <li>If any encrypted entries are encountered, you will be asked for a password, or can skip them, or stop the import.</li>
        <li>If the same password is used for multiple entries, you only need to enter it once; it'll only ask again for unique passwords.</li>
      </ul>

      <h3>Style simplification</h3>
      <ul>
        <li>Changed the style scope popup menu to rename "All at This Level" to "Level n Rows", where "n" is the selected row number.</li>
        <li>Removed the Parent/Child option, since that caused confusion.</li>
        <li>When the scope is changed to "Level n Rows", the level style is applied to the selected row.</li>
        <li>Existing documents are updated accordingly.</li>
      </ul>

      <h3>Pasting improvements</h3>
      <ul>
        <li>If only one cell is selected when pasting text with multiple lines and/or columns, it will now overwrite (and select) adjacent rows and/or columns.</li>
        <li>If more columns than are available are needed, new ones are added at the end.</li>
        <li>If more rows than are available within the parent of the selected cell, more are added at the end of that parent.</li>
        <li>If the selected cell is the last within its parent, added rows can reflect the hierarchy of the pasted text.  The hierarchy is ignored if overwriting adjacent cells, to avoid changing existing hierarchy.</li>
        <li>If multiple cells are selected, pasting only populates those cells, as before.</li>
        <li>Copying and pasting now uses the displayed column order; previously the order could be different if columns have been moved.</li>
      </ul>

      <h3>Print preview</h3>
      <ul>
        <li>Choosing the Print… command now transforms the window into a print preview.</li>
        <li>When in the print preview, a Print… button in the Inspector and the Print… menu command displays the standard print sheet to actually print.</li>
        <li>When in the print preview, a Done button in the Inspector and a File ▸ Done Printing menu command enable exiting the print preview.</li>
        <li>The print preview always uses the light appearance, even when the Mac is using the dark appearance, to match the paper.  Defaults to Plain theme, but that can be changed.</li>
        <li>The Outline and/or Timeline views are displayed so that all of their columns are visible.  The Month and Year views can not be printed yet (coming in a later version).</li>
        <li>Outline and Timeline views are fully editable in print preview mode.  Cells can be edited, rows can be collapsed or expanded, filters and focus can be used, etc.</li>
        <li>The preview can be zoomed in or out via a pinch gesture on a trackpad.</li>
        <li>The Inspector includes a Print pane with pop-up menus to choose the printer and paper size.</li>
        <li>The Print Inspector also includes graphical representations of the page to toggle between portrait and landscape, plus top, bottom, left, and right margins.</li>
        <li>The Inspector includes checkboxes to include the row numbers and/or column headings when printing.</li>
        <li>The views can be scaled via a slider or percentage field in the Inspector, so they fit a desired number of pages, with a Fit button to fit their width to one page.</li>
        <li>Page breaks are indicated with dotted lines; they are aligned to the gaps between cells.</li>
      </ul>

      <h3>Preferences</h3>
      <ul>
        <li>The Preferences window has been split into two pages, with the usual page-switching toolbar buttons.</li>
        <li>The General page includes behavior options like whether to use a template, the Return and Tab key options, etc.</li>
        <li>The Appearance page includes cosmetic options to apply as defaults in new sheets, like the theme and font, plus new options for the completion and topic style, finish date options, timeline options, and calendar options.</li>
        <li>The default text color now uses an enhanced color well, with the pop-up swatches.</li>
      </ul>

      <h3>Tweaks and fixes</h3>
      <ul>
        <li>The focus path and dismiss button are now displayed in the Topic column header instead of a separate bar.</li>
        <li>Changed the Show Completed Rows option to support three options: Show Completed, Show Completed Today, and Hide Completed.  The new today option leaves just completed rows visible until the following day, so recently completed rows can be referred to if needed.</li>
        <li>Added a pop-up menu to the Import open panel to choose the destination of the import: a new document, or a new sheet on the topmost open document.</li>
        <li>If holding Option when clicking a view in the toolbar, that view replaces the current view(s) instead of adding another one.</li>
        <li>Can now sort (via the Organize ▸ Sort Once submenu) by the Added Date to get back to the order rows were added, or by the Changed Date to see the recently edited rows.</li>
        <li>Changed the Filter menu to have the filters at the end, instead of the start, to match the Sheet menu (and other apps).</li>
        <li>Disabled the limit of making the Topic column narrower than the total custom columns, so blank space can be left to the right of the last column if desired (I can easily re-enable it if we want to go back to the old behavior).</li>
        <li>Re-sampled each of the color palette colors to match those in Apple Numbers.</li>
        <li>Changing between light and dark modes now refreshes the Inspector, so it shows the correct color.</li>
        <li>The label for links now uses a color with more contrast.  Also increased the text length before truncation, and fixed a layout issue with multiple long links.</li>
        <li>When showing or hiding the filter bar, other tabs are now updated to match that state.</li>
        <li>When hiding the note or links, now exits editing mode.</li>
        <li>The tooltip for the Connect toolbar button changes between Connect Dependency and Disconnect Dependency as appropriate.</li>
        <li>Fixed updating the dates when loading the Expense Tracker template.</li>
        <li>Replaced the icon for the Completed options in the toolbar with a tri-state one.</li>
        <li>Fixed parent rows with some completed children not appearing when showing Completed Today.</li>
        <li>Fixed changing the completed options displaying rows as collapsed when they shouldn't be.</li>
        <li>Fixed updating the Timeline and calendar views when changing the focus.</li>
        <li>Clicking a cell next to a link now selects the cell.</li>
        <li>Fixed showing notes and links in a new tab.</li>
        <li>Adjusted the dark variation of the Yellow Notepad theme.</li>
        <li>Fixed row menu items with submenus not applying their items to the clicked row.</li>
        <li>When opening a new document or sheet from a template, the Added and Changed dates are set to the current date and time, with a 1-second offset for each row, so sorting by Added Date will restore the templated order.</li>
        <li>If the dates for the Timeline are more than ten years ago, only those dates are displayed, rather than including today in the range.</li>
        <li>Added support for a minimum Timeline scale of years.</li>
        <li>The Timeline tooltip now only includes dates that have their "show in timeline" Column Inspector option checked.</li>
        <li>Fixed the undo for a Clear Value command saying Undo Delete instead of Undo Clear Cell.</li>
        <li>No longer beeps when reaching the beginning or end while arrowing between cells.</li>
        <li>Changed the name picker (e.g. for saving a smart filter) to include buttons to cancel and save the change, as alternatives to pressing the Escape or Return keys.</li>
        <li>Changed the date picker to not automatically dismiss when clicking on a part that doesn't change the date (e.g. the month name, or dates in previous or next months).</li>
        <li>When multiple rows are selected, the Edit ▸ Duplicate Row menu command becomes Duplicate Selected Rows, and similar for the row menu.</li>
        <li>If the preference for new documents is set to a fixed template, but one hasn't been explicitly chosen, now defaults to Blank (which is the first in the menu anyway).</li>
        <li>Adding rows, indenting, and outdenting are now disabled when using a filter, since they don't make sense in that context.</li>
        <li>Fixed the filter criterion value popup not always appearing for Symbol columns.</li>
        <li>Improved filter criteria on Symbol columns to work properly with unchecked symbols.</li>
        <li>The Show Note in New Tab menu command is now always visible in the row menu. (It appears in the View menu when you hold the Shift key.)</li>
        <li>Fixed a template opening in a new tab of an existing document.</li>
        <li>Fixed a crash when dragging a row into its own children; that shouldn't be and no longer is allowed.</li>
        <li>When editing a date cell via the date picker, other cells are now immediately updated. (Editing by typing in the cell updates when leaving the cell, to avoid unnecessary updates for incomplete dates.)</li>
        <li>Fixed using the date picker in the Inspector vs the Timeline or calendar info popover.</li>
        <li>Now shows the Show/Hide Completed toolbar button menu when clicking instead of clicking and releasing, for quicker access.</li>
        <li>The Connect toolbar button now shows the connected/disconnected state in its icon (with an arrow when connected, none when not). It also shows it immediately on click, rather than after completion the operation, to appear more responsive.</li>
        <li>Fixed a crash on High Sierra and Mojave when accessing the Window menu.</li>
        <li>Reimplemented the Shift-selection behavior to create rectangular selections, anchored from the initial click point (so can be reshaped with each Shift-click or Shift-arrow), in the same way as Numbers etc.</li>
        <li>Updated the row selection functions (clicking the row number to select the row, the Select row commands, etc) to select the whole row and support rectangular selection adjustments.</li>
        <li>Fixed undoing the Timeline Shade Weekends option in the Inspector.</li>
        <li>Fixed undoing dragging the Timeline bars.</li>
        <li>Improved linking to a website when the URL has spaces around it, by trimming off those spaces.</li>
        <li>When dragging files to a document to link to them, now only highlights the row under the drag.</li>
      </ul>

      <hr />

      <h2>SheetPlanner 1.2.1</h2>
      <time>Feb 4, 2020</time>
      <ul>
        <li>Fixed an issue where dragging parents and children could lose their hierarchy.</li>
        <li>When copying selected cells, the text lines for child rows are now prepended with spaces to preserve the hierarchy.</li>
        <li>When pasting text with a single cell selected, or while editing, when adding rows it now looks for space prefixes to preserve the hierarchy.</li>
        <li>Fixed the Show All Notes and Show All Links not toggling to their Hide variations.</li>
        <li>Fixed inability to change the Style Inspector from All at This Level to Parent/Children at Level.</li>
        <li>Fixed the text color defaulting to black on dark mode for some new users.</li>
        <li>The Preferences window now updates the text color when changing between light and dark modes.</li>
      </ul>

      <hr />

      <h2>SheetPlanner 1.2</h2>
      <time>Dec 13, 2019</time>
      <ul>
        <li>Added a new Picture column type, that can include pictures within the document.</li>
        <li>Pictures can be dragged or pasted, shown as thumbnails, and have a larger Quick Look view.</li>
        <li>Can now optionally choose multiple items in List cells.</li>
        <li>Each cell can now have its own style, including font, face, size, text style, justification, and color.</li>
        <li>Replaced the Style Inspector font button with separate pop-up menus, and added an alignment control.</li>
        <li>Can now add a notification for Date cells, a specified interval before the date.</li>
        <li>The Timeline view now supports an editable info popover when double-clicking on a bar.</li>
        <li>Added a Date Stamp function.</li>
        <li>Plus numerous other improvements; see the website for details.</li>
      </ul>

      <hr />

      <h2>SheetPlanner 1.1.1</h2>
      <time>Jul 27, 2019</time>
      <ul>
        <li>Changing a Date column color, or toggling the show checkboxes, is now immediately reflected in the Timeline view.</li>
        <li>Fixed the Outline Columns submenu showing incorrect column names after adding or removing columns.</li>
        <li>The Progress column is now shown in the Project Plan template.</li>
        <li>If you open a template then close it without making any changes, it now doesn't ask if you want to keep it.</li>
      </ul>

      <hr />

      <h2>SheetPlanner 1.1</h2>
      <time>Jun 25, 2019</time>
      <ul>
        <li>Added the ability to connect rows to establish a dependency.</li>
        <li>Dependencies are displayed via arrows in the Timeline, and new optional columns in the Outline.</li>
        <li>A red vertical line now marks the current date & time in the Timeline.</li>
        <li>Any Date column can now be included in the Timeline.</li>
        <li>New Symbol column type, including checkbox, flag, star, color icons, bullet journaling, Eisenhower quadrants, 5 stars, etc.</li>
        <li>New Progress column type, displayed as a progress bar, mini pie chart, or percentage.</li>
        <li>Auto-enter cell values with a default value, from the row above, today, formatted serial number, and more.</li>
        <li>Outline view menu improvements.</li>
        <li>Filter improvements, including better search for dates, time intervals, note text, and more.</li>
        <li>Printing the Timeline is now supported, and printing the Outline is improved.</li>
        <li>Plus numerous other improvements; see the website for details.</li>
      </ul>

      <hr />

      <h2>SheetPlanner 1.0.1</h2>
      <time>Jan 4, 2019</time>
      <ul>
        <li>Fixed a layout issue with the calendar views when always showing scrollbars is enabled in the System Preferences.</li>
        <li>Fixed issues with editing Number column values in some Euro countries.</li>
        <li>Fixed a crash that could occur when changing Preferences after closing a document.</li>
        <li>Fixed an issue where a document could not be opened.</li>
      </ul>

      <hr />
      <Link to="/"><span className={"link"}>Go back to the homepage</span></Link>
    </Content>
  </Layout>
)

export default ReleaseNotesPage
